import React from 'react';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	textTransform: 'uppercase',
	background: 'transparent',
	color: '#000',
	border: 'none',
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
		{...props}
	/>
))(({ theme }) => ({
	color: '#000',
	background: 'transparent',
	flexDirection: 'row-reverse',
	padding: 0,
	'& .MuiAccordionSummary-expandIconWrapper': {
		transition: 'transform 0.s ease'
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'&[data-disabled=true]': {
		cursor: 'default !important'
	},
	'&[data-disabled=true] .MuiAccordionSummary-expandIconWrapper': {
		opacity: 0
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: 0
}));

function Event({ event = [], expandedId, handleOpen }) {
	// const [expanded, setExpanded] = useState(false);

	// const handleChange = () => (event, newExpanded) => {
	// 	setExpanded(!expanded);
	// };

	const onClickBuy = (e) => {
		e.stopPropagation();
		// window.location = event.meta["event_ticket-url"];
		window.open(event.meta["event_ticket-url"], '_blank');
	}

	const actionElement = event.meta["event_ticket-url"] && !event.meta["event_sold-out"] ? (
		<button style={{ flex: '0 0 100px', marginTop: '10px', marginLeft: '10px' }} onClick={onClickBuy}>Tickets Kaufen</button>
	) : (
		<span style={{ flex: '0 0 100px', marginTop: '10px', marginLeft: '10px' }} className="Event__Soldout">Ausverkauft</span>
	)

	return (
		<Accordion expanded={event.id === expandedId} onChange={() => handleOpen(event.id)}>
			<AccordionSummary data-disabled={!(event.meta.event_tickets && event.meta.event_tickets.length > 0)}>
				<Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '0px !important', flexWrap: 'no-wrap', marginTop: '-10px' }}>
					<Stack sx={{ flex: '0 1 auto', justifyContent: 'center', marginTop: '20px' }}>
						<Container sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', padding: '0px !important', margin: '0 0 10px' }}>
							<Typography sx={{ marginRight: '10px', textDecoration: 'underline', textDecorationColor: '#999999', textDecorationThickness: '3px', textDecorationStyle: 'solid' }}>{event.meta.event_date} {event.meta.event_city}</Typography>
							<Typography>{event.meta.event_venue}</Typography>
						</Container>
						<Container sx={{ display: 'flex', justifyContent: 'flex-start', padding: '0px !important' }}>
							{event.meta.notice_title &&
								<Typography sx={{ fontSize: '0.7rem' }}>{event.meta.notice_title}</Typography>
							}
						</Container>
					</Stack>
					{actionElement}
				</Container>
			</AccordionSummary>
			{ event.meta.event_tickets && event.meta.event_tickets.length > 0 && (
				<AccordionDetails>
					<Stack>
						<Container sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
							{event.meta.notice_subtitle &&
								<Typography sx={{ fontSize: '0.5rem' }}>{event.meta.notice_subtitle}</Typography>
							}
						</Container>
						<Container sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
							<Table size="small" aria-label="List of valid tickets for this event">
								<TableBody>
									{event.meta.event_tickets.map((ticket) => (
										<TableRow>
											<TableCell component="th" scope="row" sx={{ color: '#000' }}>
												{ticket.ticket_date}
											</TableCell>
											<TableCell align="right" sx={{ color: '#000' }}>{ticket.ticket_city}</TableCell>
											<TableCell align="right" sx={{ color: '#000' }}>{ticket.ticket_venue}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Container>
					</Stack>
				</AccordionDetails>
			)}
		</Accordion>
	)
}

export default Event;