function Imprint() {
	return (
		<div className="Imprint">
			<h2>DATENSCHUTZ</h2>
			<p>
				Datenschutzbestimmungen<br />
				der Website <a href="https://fabersingt.com">https://www.fabersingt.com</a>, welche betrieben wird von Irrsinn Management, Irrsinn Management Boutahar & Schenk GbR, Neptunplatz 6a, Köln.<br />
				Verantwortlich i.S.d. DSGVO, Datenschutz allgemein
			</p>
			<p>
				Verantwortlich i.S.d. Datenschutzgrundverordnung (DSGVO) und für die Einhaltung sonstiger datenschutzrechtlicher Bestimmungen ist:<br />
				Irrsinn Management, Irrsinn Management Boutahar & Schenk GbR, Neptunplatz 6a, 50823 Köln, +49 221 977 656 11, salut@irrsinnig.com.<br />
				Wir nehmen den Schutz personenbezogener Daten sehr ernst. Wir beachten beim Betrieb unserer Website die gesetzlichen Datenschutzbestimmungen. Wir möchten, dass Ihr wisst, wann wir welche Daten erheben, verarbeiten und nutzen und welche Rechte Euch im Hinblick auf die Verwendung von Euren Daten zustehen.<br />
				Personenbezogene Daten sind Informationen, die dazu genutzt werden können, Eure Identität zu erfahren. Darunter fallen Informationen wie Euer Name, Adresse, Postanschrift, Telefonnummer, IP- Adresse und E-Mail-Adresse. Informationen, die nicht mit Eurer Identität in Verbindung gebracht werden (wie zum Beispiel die Anzahl der Nutzerinnen und Nutzer der Website), fallen nicht darunter. Ihr könnt unsere Website grundsätzlich ohne Offenlegung Eurer Identität, also ohne Eingabe der personenbezogenen Daten, nutzen. Diese Informationen (bspw. Datum und Uhrzeit des Zugriffs, URL (die Euch zu uns geführt hat und ggf. die Ihr über unsere Website aufruft), Browser, Betriebssystem, Internet- Service- Provider, IP- Adresse) werden in einem Logfile abgespeichert. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO. Ausnahmen gelten für die entsprechend gekennzeichneten Bereiche, wie unser Kontaktformular und die Bestellmöglichkeit unseres Newsletters. Die Speicherung von Logfiles findet statt, um die Funktionsfähigkeit, Optimierung und Sicherheit unserer Website sicherzustellen. Diese Informationen werden automatisch erhoben, es gibt keine Widerspruchsmöglichkeit. Logfiles werden in der Regel nach sieben Tagen gelöscht oder verfremdet, so dass eine Zuordnung nicht mehr möglich ist.<br />
				Rechtsgrundlagen nach der DSGVO
			</p>
			<p>
				Wir sind verpflichtet, Euch die Rechtsgrundlagen für die Verarbeitung personenbezogener Daten zu nennen:<br />
				Wir dürfen Eure personenbezogenen Daten grundsätzlich nur nach Eurer Einwilligung erheben und verarbeiten, Art. 6 Abs. 1 lit. a DSGVO, es sei denn, eine vorherige Einholung ist nicht möglich oder das Gesetz hat Ausnahmen bestimmt.<br />
				Wir verarbeiten die über Euch erhobenen personenbezogenen Daten zur Erfüllung unseres Vertrages oder auch bereits Vertragsanbahnungsverhältnisses. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b DSGVO. Weitere Rechtsgrundlagen für die Verarbeitung personenbezogener Daten ist die Erfüllung unserer rechtlichen Verpflichtungen Art. 6 Abs. 1 lit.c. DSGVO oder die Wahrung Eurer lebenswichtiger Interessen oder einer anderen natürlichen Person Art. 6 Abs. 1 lit. d DSGVO.<br />
				Wir dürfen personenbezogene Daten ferner bei einem berechtigten Interesse unseres Unternehmens gemäß Art. 6 Abs. 1 lit.f DSGVO verarbeiten, wenn in einer vorzunehmenden Abwägung Eure Interessen, Grundrechte und Grundfreiheiten unsere Interessen nicht überwiegen.<br />
				Wir müssen Euch auch darauf hinweisen, dass die Datenübertragung im Internet mit Sicherheitsrisiken verbunden ist und ein lückenloser Schutz der Daten vor dem Zugriff Dritter daher nicht gewährleistet werden kann. Achtet bitte Eurerseits darauf, dass Eure Daten und Euer Endgerät vor dem unberechtigten Zugriff Dritter und mit einer stets aktuellen Sicherheitssoftware geschützt sind.<br />
				Eure Angaben speichern wir auf besonders geschützten Servern in Deutschland. Unsere Sicherheitsmaßnahmen verbessern wir fortlaufend entsprechend der technischen Entwicklung, bspw. ist unsere Website SSL verschlüsselt. Die Speicherung, Verarbeitung und Nutzung Eurer Daten erfolgt bis auf die in diesen Datenschutzbestimmungen geregelten besonderen Fällen im Übrigen nur zur Kontaktaufnahme mit Euch sowie zur Zusendung des von Euch bestellten Newsletters. Der Zugriff darauf ist nur wenigen besonders befugten Personen möglich, die bspw. mit der technischen und inhaltlichen Betreuung des Bestellvorgangs und der Zusendung befasst sind.<br />
				Kontaktformular
			</p>
			<p>
				Bei unserem Kontaktformular haben wir notwendige Angaben im Gegensatz zu freiwilligen Angaben gekennzeichnet und verweisen auf unsere Datenschutzbestimmungen. Für einen beabsichtigten Vertragsschluss lautet die Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO. Eure Nachricht und Eure E- Mail- Adresse löschen wir, wenn wir Eure Nachricht beantwortet haben oder ein Vertragsverhältnis erkennbar nicht zustande kommt oder beendet ist. Ein Widerruf der Verarbeitung personenbezogener Daten über das Kontaktformular ist jederzeit unter salut@irrsinnig.com möglich und führt zur Löschung aller Daten.<br />
				Marketingzwecke/ Newsletter
			</p>
			<p>
				Eure Angaben dürfen wir nur mit Eurer vorherigen Einwilligung für Marketingzwecke verwenden. Wenn Ihr Euch für unseren kostenlosen “Newsletter“ eintragt, willigen Ihr ein, regelmäßig Neuigkeiten über Faber und interessante Angebote aus dem Produktsortiment von Faber über die von Euch angegebene E- Mail- Adresse zu erhalten. Zu diesem werblichen Zweck wird Eure E- Mail von uns, dem „Irrsinn Management“, Irrsinn Management Boutahar & Schenk GbR, Neptunplatz 6a, 50823 Köln, verwendet. Ihr müsst uns eine gesonderte Einwilligung unter Verweis auf unsere Datenschutzbestimmungen erteilen, dies geschieht durch das Ankreuzen der Belehrung vor Aufgabe der Bestellung des Newsletters. Rechtsgrundlage für die Zusendung des Newsletters ist Art. 6 Abs. 1 lit. a DSGVO. Wir erheben auch Eure IP- Adresse und das Datum sowie die Uhrzeit Eurer Anmeldung und Bestätigung nach dem sog. “Double-Opt-In- Verfahren“. Diesen Newsletter könnt Ihr jederzeit, ohne dass hierfür andere Kosten als die Übermittlungskosten nach den Basistarifen entstehen, unter salut@irrsinnig.com abbestellen, dies stellt einen Widerruf Eurer Einwilligung dar. Eure für die Anmeldung angegebene bei uns hinterlegte E- Mail- Adresse wird dann gelöscht. Eine entsprechende Abbestellfunktion “Abmelden/ Unsubscribe“ finden Ihr zusätzlich auch im Rahmen eines jeden versendeten Newsletters.<br />
				Nutzung des Versanddienstleisters “MailChimp“
			</p>
			<p>
				Wir haben uns hinsichtlich des Versandes und der Auswertung unserer Newsletter für den US- Versanddienstleister “MailChimp“,The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA, entschieden (nachfolgend: “MailChimp“ genannt). Für die Dienstleistung in unserem Auftrag erhält und speichert MailChimp die E- Mail- Adressen unserer Kunden, weitere Angaben, (z.B. IP- Adresse) sowie die Protokollierung der Daten, die im Rahmen des Anmelde- und Bestätigungszeitpunktes der Newsletterbestellung unserer Kunden nach Maßgabe des von uns genutzten sog. “Double- Opt- In- Verfahrens“ erfasst werden.<br />
				MailChimp ist nicht berechtigt, unsere Kunden anzuschreiben oder Eure Daten weiterzugeben. MailChimp nutzt die Daten jedoch zur Optimierung ihres eigenen Versandservices. Ferner gelangt Ihr direkt auf die Seite von MailChimp, wenn Ihr bspw. nachträglich Eure E- Mail- Adresse zum Versand korrigiert oder einen Newsletter online abruft. Auf der Website von MailChimp werden Cookies gesetzt und personenbezogene Daten erhoben. Die Datenschutzbestimmungen von MailChimp könnt Ihr (in englischer Sprache) im Einzelnen hier einsehen: https://mailchimp.com/legal/privacy/.<br />
				Um das Leseverhalten unserer Kunden zu kennen und um unser Marketing zu verbessern, haben wir unsere Newsletter mit einer Datei versehen, die beim Öffnen des Newsletters von MailChimp abgerufen wird. Damit erhält MailChimp über deren Server Informationen über Euren Browser, Zeitpunkt und Ort des Abrufs sowie Eure IP- Adresse. Statistisch (dem Newsletter- Empfänger zuordenbar) können wir damit erfassen, wann, wie oft und in welcher Weise unsere Newsletter geöffnet wurden und ob Links betätigt wurden.<br />
				Die Nutzung des Versanddienstleisters MailChimp erfolgt auf Grundlage von Art. 6 Abs. 1 lit f DSGVO. Unser berechtigtes Interesse stützen wir auf unser geschäftliches Anliegen dahingehend, dass unsere Newsletter bedienungsfreundlich und kundenoptimiert sind. Wir haben mit MailChimp ein “Data- Processing- Agreement“ vgl. (https://mailchimp.com/legal/forms/data-processing-agreement/) abgeschlossen, im Rahmen dessen MailChimp sich verpflichtet, Datenschutzvorgaben nach dem Maßstab unserer EU- Standardvertragsklauseln einzuhalten, insbesondere Eure Daten zu schützen. Ferner ist MailChimp unter dem zwischen der EU und dem USA abgeschlossenen Datenschutzabkommen “Privacy Shield“ https://www.privacyshield.gov/welcome zertifiziert (https://www.privacyshield.gov/list).<br />
				Weitergabe personenbezogener Daten an Dritte
			</p>
			<p>
				Wir geben Eure Daten nicht ohne Eure ausdrückliche Einwilligung an Dritte weiter. Wir sind als Management von Faber beauftragt, Anfragen zu bearbeiten und an ihn weiterzuleiten sowie den Newsletter zu versenden. Zu einer Übermittlung von Daten an auskunftsberechtigte Stellen verpflichten wir uns gemäß den gesetzlichen Bestimmungen.<br />
				Einsatz von Cookies, Deaktivierungsmöglichkeit, Cookie- Banner
			</p>
			<p>
				Bei der Benutzung unserer Website kommen Cookies zum Einsatz. Die meisten Browser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Ihr könnt das Speichern von Cookies jedoch einschränken, deaktivieren oder Euren Browser so einstellen, dass er Euch benachrichtigt, sobald Cookies gesendet werden. Ihr könnt auch bereits gespeicherte Cookies wieder löschen. Bitte informiert Euch über Art und Weise in den Bedienungshinweisen Eures Browser- Herstellers. Die vollständige Deaktivierung kann jedoch die Funktionsfähigkeit unserer Website einschränken, so dass Ihr ggf. nicht alle Funktionen einwandfrei nutzen können.
			</p>
			<p>
				Cookies sind kleine Textdateien, die auf Eurem Rechner abgelegt werden und die Euer Browser speichert, um Euch ein möglichst individuell gestaltetes Angebot präsentieren zu können, deswegen könnt Ihr auch über den Einsatz von Cookies frei entscheiden. Greift Ihr auf unsere Website zu, liefert der Server des Anbieters neben der Seite dem Browser von Euch ggf. auch das Cookie mit zurück. Der Browser entscheidet dann je nach den lokalen Einstellungen, ob das Cookie auf Eurem Rechner gespeichert wird. Cookies können als reine Textdateien nicht in das System von Euch eindringen, oder dort Schaden anrichten. Die Textdateien werden vom Server einer gewählten Website gesetzt und beim nächsten Besuch auf dieser Website von Eurem Browser an den von uns genutzten Server geschickt. Wir setzen Cookies für das Zählen von Seitenaufrufen ein. Hierbei wird beim Nutzer eine Zufallszahl – eine Art Pseudonym – in Form des Cookies gesetzt, anhand derer der Server erkennt, dass die Anfrage vom selben Nutzer erfolgt, ohne aber Informationen über die Identität oder sonstige Daten des Nutzers zu erhalten. Derartige Cookies haben überdies in der Regel nur eine Lebensdauer von 24 Stunden. Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Ebenso können wir ein Cookie mit langer Lebensdauer setzen, das die technischen Einstellungen Eures Browser überprüft und als erste Information beim neuen Besuch auf unsere Website ausgelesen wird. Diese Cookies erkennen den aufrufenden Browser auch nach dem Ende einer Session und erneutem Seitenaufruf und dienen dazu, unsere Website anwendungsfreundlicher zu gestalten. Durch die Verwendung technisch notwendiger Cookies erstellen wir keine Nutzerprofile. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO.<br />
				Beim Aufruf unserer Website erscheint ein Banner, der Euch informiert, dass wir Cookies setzen. Wir verweisen im Rahmen unseres Banners auf diese Datenschutzbestimmungen. Der Banner lässt wesentliche Informationen unserer Website unverdeckt.
			</p>
			<p>
				Allgemeine Datenschutzbestimmungen hinsichtlich der Einbindung und Hinweise auf unsere sozialen Netzwerke und die von uns genutzten Video- und Musikplattformen.<br />
				Wir weisen Euch darauf hin, dass Faber auch in den sozialen Netzwerken Facebook, Instagram, sowie auf der Videoplattform YouTube vertreten ist und dass Ihr ihn auch bei den Musikonlinediensten ITunes und Spotify findet, diese Hinweise finden Ihr auf unserer Website oben mit den entsprechenden Icons (Bilder). Alle Icons sind lediglich als Verlinkung ausgestaltet und nicht als Plugin. Bei einem Plugin werden im Gegensatz zu einer Verlinkung unmittelbar, wenn Ihr unsere Website mit dem betreffenden Icon aufruft, Informationen an den Server des jeweiligen sozialen Netzwerkanbieters bzw. der Video- oder Musikplattform übermittelt. Nur wenn Ihr auf unserer Website der Verlinkung folgt, indem Ihr auf das Icon klickt, stellt Euer Browser automatisch eine direkte Verbindung zu den Servern des sozialen Netzwerkanbieters bzw. der Video- oder Musikplattform her, auch wenn Ihr dort ggf. kein Profil besitzt oder nicht eingeloggt seid.<br />
				Soweit wir YouTube – Videos auf unserer Seite eingebettet haben, wird zwar eine direkte Verbindung zu dem Server von YouTube hergestellt, wir haben jedoch den erweiterten Datenschutzmodus aktiviert, so dass zumindest keine Verbindung mehr zu dem Werbedienst DoubleClick hergestellt werden kann und fast keine Cookies mehr gesetzt werden.<br />
				Durch diese Einbindung erhält der jeweilige soziale Netzwerkbetreiber bzw. die Video- oder Musikplattform die Information, dass Euer Browser (einschließlich der Angabe Ihrer IP-Adresse) die entsprechende Seite unserer Website aufgerufen hat, dies wird an den Server des jeweiligen Betreibers übermittelt und dort gespeichert. Hast Du ein Profil und bist Du eingeloggt, kann der jeweilige Netzwerkbetreiber den Besuch unserer Website Deinem Profil unmittelbar zuordnen und speichern. Unser berechtigtes Interesse stützen wir auf Art. 6 Abs. 1 lit. f DSGVO, um unseren Bekanntheitsgrad zu steigern und Euch auf den Mehrwert aufmerksam zu machen. Wenn Ihr vermeiden wollt, dass der soziale Netzwerkanbieter die über unsere Website gesammelten Daten unmittelbar Eurem Profil zuordnet, müsst Ihr Euch vor Eurem Besuch unserer Website in dem sozialen Netzwerk, bzw. der Video- oder Musikplattform ausloggen. Ihr könnt das Laden der Plugins auch mit Add- Ons für Euren Browser komplett verhindern.<br />
				Facebook wird betrieben von der Facebook Inc.,1601 Willow Road, Menlo Park, California, 94025, USA („Facebook“). Weitere Informationen über den Zweck und Umfang und die Verarbeitung der Datenerhebung sowie Informationen über die Einstellungsmöglichkeiten zum Schutz Eurer Privatsphäre entnehmt Ihr bitte den Datenschutzhinweisen von Facebook: https://www.facebook.com/policy.php .<br />
				Instagram wird betrieben von der Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA („Instagram“). Unter dem nachfolgenden Link könnt Ihr Euch weitergehend bei Instagram über die Datenschutzbestimmungen informieren: https://help.instagram.com/519522125107875?helpref=page_content.<br />
				Twitter wird betrieben von der Twitter Inc.,1355 Market St, Suite 900, San Francisco, CA 94103, USA („Twitter“). Weitere Informationen über den Zweck und Umfang und die Verarbeitung der Datenerhebung sowie Informationen über die Einstellungsmöglichkeiten zum Schutz Eurer Privatsphäre entnehmt Ihr bitte den Datenschutzhinweisen von Twitter: https://twitter.com/de/privacy .<br />
				Spotify wird betrieben von Spotify AB, Regeringsgatan 19, SE-111 53 Stockholm, Schweden („Spotify“). Weitere Informationen über den Zweck und Umfang und die Verarbeitung der Datenerhebung sowie Informationen über die Einstellungsmöglichkeiten zum Schutz Eurer Privatsphäre entnehmt Ihr bitte den Datenschutzhinweisen von https://www.spotify.com/de/legal/privacy-policy/.<br />
				YouTube wird betrieben von der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („YouTube“). Informationen über den Zweck und Umfang und die Verarbeitung der Datenerhebung sowie Informationen über die Einstellungsmöglichkeiten zum Schutz Eurer Privatsphäre entnehmt Ihr bitte den Datenschutzhinweisen von YouTube (diese sind weitergeleitet auf die Datenschutzhinweise von Google): https://policies.google.com/privacy?hl=de&gl=de .<br />
				ITunes wird betrieben von Apple Inc., 1 Infinite Loop, Cupertino, CA 95014, USA („iTunes“). Unter den nachfolgenden Links könnt Ihr Euch weitergehend bei ITunes über die Datenschutzbestimmungen informieren: https://www.apple.com/de/privacy/approach-to-privacy/ und https://www.apple.com/legal/privacy/de-ww/.<br />
				Integrierte Diensteanbieter: Linkfire, opt- out- Möglichkeit
			</p>
			<p>
				Wir nutzen auf unserer Website „Linkfire“, ein Musik- Vertriebs- Analyse- Tool. Linkfire wird betrieben von der Linkfire ApS, Artillerivej 86, 3tv., 2300 Kopenhagen S, Dänemark („Linkfire“). Mit Hilfe von Linkfire können wir Euch verschiedene Onlinemusikdienste, die Faber vertreiben, übersichtlich anzeigen. Linkfire möchte analysieren, wie Nutzer die einzelnen Links genutzt haben und mit Linkfire verbundenen Unternehmen Euer Nutzungsverhalten mitteilen. Diese Daten werden auf den Servern von Linkfire in Dänemark gespeichert. Weitere Informationen und Einstellungen zum Schutz Eurer Privatsphäre findet Ihr unter den Datenschutzbestimmungen von Linkfire: https://help.linkfire.com/hc/en-us/articles/360003273554-Privacy-Portal. Ihr habt die Möglichkeit zu verhindern, dass Linkfire Daten über Euch sammelt: https://help.linkfire.com/hc/en-us/articles/360003273554-Privacy-Portal#h_843765285401525169613598. Rechtsgrundlage für die Einbindung von Linkfire ist Art. 6 Abs. 1 S. 1 lit. f DSGVO und unser wirtschaftliches Interesse an der Auswertung der Produkte des Künstlers Faber. Linkfire wurde beauftragt von unserem Partner Universal Music Group, bei denen Faber derzeit unter Vertrag steht. Daten werden nur mit Zustimmung analysiert, deshalb öffnet sich eine “Privacy Notice“, eine Information über Eure privaten Einstellungsmöglichkeiten, im Rahmen derer Ihr der Verarbeitung Eurer Daten zustimmen oder ablehnen könnt. Daten werden spätestens nach 6 Monaten gelöscht.<br />
				Betroffenenrechte (Auskunftsrecht, Recht auf Berichtigung und Einschränkung, Löschung, Widerrufsrecht, Datenübertragungsrecht, Euer Recht bei automatisierten Entscheidungen, Widerspruchsrecht, Unterrichtungsrecht, Beschwerderecht bei der Aufsichtsbehörde).<br />
				Die DSGVO stärkt Eure Rechte als Betroffene, wenn personenbezogene Daten verarbeitet werden. Die Ausübung Eurer Rechte ist kostenlos.<br />
			</p>
			<p>
				Auskunftsrecht: Wir sind verpflichtet, Euch auf Antrag Auskunft zu erteilen über die zu Eurer Person gespeicherten Daten, auch, soweit sie sich auf die Herkunft dieser Daten beziehen, die Empfänger oder Kategorien von Empfängern, den Zweck und die Dauer der Speicherung, ggf. unter Nennung von Ihren Kriterien, Art. 15 DSGVO.
			</p>
			<p>
				Berichtigungsrecht: Euer Recht auf Berichtigung umfasst personenbezogene Daten, die nicht vollständig oder unrichtig bei uns erhoben bzw. verarbeitet werden, Art. 16 DSGVO.
			</p>
			<p>
				Löschungsrecht: Ihr besitzt ein Löschungsrecht, wenn personenbezogene Daten unrechtmäßig verarbeitet wurden, der Zweck der Datenverarbeitung nicht mehr vorliegt, Ihr Eure Einwilligung widerruft oder Widerspruch erhoben habt und keine anderweitige gesetzliche Grundlage für die Verarbeitung besteht, Art. 17 Abs. 1 DSGVO. Ein Löschungsanspruch besteht nicht, wenn wir uns auf die Wahrnehmung unserer Rechtsansprüche berufen dürfen, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses, zur übertragenen Ausübung öffentlicher Gewalt oder aufgrund des Rechts der Meinungsfreiheit.
			</p>
			<p>
				Einschränkungsrecht: Euer Recht auf Einschränkung in die Verarbeitung Eurer personenbezogenen Daten besitzt Ihr gemäß Art 18 DSGVO, wenn Ihr statt der Löschung die Einschränkung verlangt, Ihr Eure Daten für Eure Rechtsansprüche benötigt oder Ihr die Richtigkeit der personenbezogenen Daten bestreitet.
			</p>
			<p>
				Widerrufsrecht: Ihr könnt jederzeit Eure einmal erteilte Einwilligungserklärung für die Zukunft widerrufen, Art. 7 Abs. 3 DSGVO.
			</p>
			<p>
				Datenübermittlungsrecht: Euch steht das Recht auf Datenübertragbarkeit zu, Art. 20 DSGVO, dahingehend, dass Ihr Eure personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format erhaltet. Ferner steht Euch das Recht zu, diese Daten ggf. einem Dritten zu übermitteln.<br />
				Euer Recht bei automatisierten Entscheidungen im Einzelfall einschließlich Profiling hat gemäß Art. 22 DSGVO den Inhalt, nicht einer Entscheidung auf Grundlage einer automatisierten Verarbeitung unterworfen zu sein, die Euch erheblich beeinträchtigt. Eine Ausnahme kann neben Eurer Einwilligung dann bestehen, wenn eine Erforderlichkeit zu bejahen ist im Rahmen des Vertragsverhältnisses oder gesetzliche Vorschriften dies vorschreiben und gleichzeitig Maßnahmen Euch hinreichend schützen.<br />
			</p>
			<p>
				Beschwerderecht bei der Aufsichtsbehörde: Soweit Ihr der Ansicht seid, dass die Verarbeitung Eurer personenbezogener Daten gegen datenschutzrechtliche Bestimmungen, insbesondere die DSGVO, verstößt, habt Ihr das Recht der Beschwerde bei einer Aufsichtsbehörde, Art. 77 DSGVO. Zuständig ist die Aufsichtsbehörde des Ortes des von Euch monierten Verstoßes, Eures Arbeitsplatzes oder in dem Mitgliedstaat Eures Aufenthaltsortes. Weitergehende Rechtsbehelfe bleiben hiervon unberührt.<br />
			</p>
			<p>
				Gesondertes Widerspruchsrecht: Ihr besitzt ein jederzeit auszuübendes Widerspruchsrecht gegen die Verarbeitung Eurer personenbezogener Daten, Art. 21 DSGVO, wenn die Verarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt. Dieses Recht habt Ihr insbesondere, um Direktwerbung zu widersprechen.<br />
			</p>
			<p>
				Ihr erreicht uns unter: Irrsinn Management, Irrsinn Management Boutahar & Schenk GbR, Neptunplatz 6a, 50823 Köln, +49 221 977 656 11, salut@irrsinnig.com.<br />
				Soweit Ihr Eure Betroffenenrechte gegenüber uns ausgeübt habt, besitzen Ihr ein Recht auf Unterrichtung dahingehend, dass wir allen Empfängern, denen wir Eure personenbezogenen Daten offengelegt haben, die Ausübung Eurer Betroffenenrechte mitteilen, Art. 19 DSGVO.
			</p>
			<h3>Dauer der Datenspeicherung, Datenlöschung</h3>
			<p>
				Wir speichern Eure Daten nur so lange, wie es durch die genannten Normen der DSGVO vorgeschriebene Speicherfrist erlaubt, und/oder der von Euch ggf. gewünschten Kontaktmöglichkeit und Newsletterzusendung notwendig ist. Die Speicherung zur Sicherung unserer eigenen Ansprüche, gesetzliche Aufbewahrungs- und ggf. damit verbundene Herausgabeansprüche, bleiben hiervon unberührt. Andernfalls werden personenbezogene Daten gelöscht oder gesperrt.
			</p>
			<h3>Geltung und Änderungen von Datenschutzbestimmungen</h3>
			<p>
				Wir behalten uns aufgrund gesetzlicher Vorgaben oder der Weiterentwicklung der Website vor, die Datenschutzbestimmungen zu ändern.<br />
				Die aktuellen Datenschutzbestimmungen könnt Ihr jederzeit auf unserer Website einsehen und ausdrucken.
			</p>
			<p>Köln, Irrsinn Management Boutahar & Schenk GbR, mit Wirkung zum 25. Mai 2018</p>
		</div>
	)
}

export default Imprint;