import React from 'react';

function Footer() {
	return (
		<div className="Footer">
			<span className="Footer__Copyright">Faber 2021</span>
			<span className="Footer__Links">
				<a href="./imprint">Impressum</a>
				<a href="./privacy">Datenschutz</a>
			</span>
		</div>
	);
}

export default Footer;