import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	app: {},
	status: 'idle',
	error: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchApp = createAsyncThunk(
	'wordpress/fetchApp',
	async () => {
		console.log("fetchApp");
		// Async request to Wordpress to fetch app data
		const response = await axios.get("app");
		console.log("Response", response);
		// The value we return becomes the `fulfilled` action payload
		return response.data;
	}
);

export const wordpressSlice = createSlice({
	name: 'wordpress',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {

	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		[fetchApp.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchApp.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.app = action.payload;
		},
		[fetchApp.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message
		}
	}
})

export default wordpressSlice.reducer;