import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import './index.css';
import App from './App';
import { store } from './features/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

const THEME = createTheme({
	typography: {
		"fontFamily": `"Futura Std", sans-serif`,
		"fontSize": 14,
		"fontWeightLight": 300,
		"fontWeightRegular": 400,
		"fontWeightMedium": 500
	}
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={THEME}>
			<Provider store={store}>
				<StyledEngineProvider injectFirst>
					<App />
				</StyledEngineProvider>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
