import React, { useState } from 'react';
import { filter } from 'lodash';

import Event from './Event';

function applySortFilter(array, query) {
	console.log("applySortFilter", array)
	if (!array || array.length === 0) return [];

	let filteredArray = [];

	if (query) {
		filteredArray = filter(array, (_event) => {
			const includesCity = _event.meta.event_city.toLowerCase().indexOf(query.toLowerCase()) !== -1;
			const includesVenue = _event.meta.event_venue.toLowerCase().indexOf(query.toLowerCase()) !== -1;
			const includesTicketWithCity = (_event.meta.event_tickets || []).filter(ticket => ticket.ticket_city.toLowerCase().indexOf(query.toLowerCase()) !== -1).length > 0;
			const includesTicketWithVenue = (_event.meta.event_tickets || []).filter(ticket => ticket.ticket_venue.toLowerCase().indexOf(query.toLowerCase()) !== -1).length > 0;
			debugger;
			return includesCity || includesVenue || includesTicketWithCity || includesTicketWithVenue;
		});
	} else {
		filteredArray = array;
	}

	const stabilizedThis = filteredArray.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		var aa = a[0].meta.event_date.split('/').reverse().join(),
        	bb = b[0].meta.event_date.split('/').reverse().join();
    	const order = aa < bb ? -1 : (aa > bb ? 1 : 0);
		if (order !== 0) return order;
		return a[1] - b[1];
	});


	return stabilizedThis.map((el) => el[0]);
}

function Events({ events = [], status = '', title = "", banner = null }) {
	const [filterCity, setFilterCity] = useState('');

	const handleFilterChange = (event) => {
		setFilterCity(event.target.value);
	}

	const [expandedId, setExpanded] = useState(false);

	const handleOpen = (id) => {
		setExpanded(id === expandedId ? false : id);
	};

	const filteredEvents = applySortFilter(events, filterCity);

	if (status === 'failed') {
		return (
			<div className="Events">
				<div className="Events__Header">
					<h1>{title}</h1>
				</div>
				<h3>Veranstaltungen konnten nicht geladen werden :(</h3>
			</div>
		)
	}

	return (
		<div className="Events">
			<div className="Events__Header">
				<h1>{title}</h1>
				<input
					placeholder="Veranstaltungsort suchen..."
					value={filterCity}
					onChange={handleFilterChange}
				/>
			</div>
			{banner}
			{ filteredEvents.length > 0 &&
				<div className="Events__AccordionContainer">
					{filteredEvents.map((event) => (
						<Event event={event} expandedId={expandedId} handleOpen={handleOpen} />
					))}
				</div>
			}
			{ filteredEvents.length === 0 &&
				<h3>Keine Events gefunden...</h3>
			}
		</div>
	);
}

export default Events;