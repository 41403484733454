import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	background: '#999999',
	color: '#000',
	border: 'none',
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
		{...props}
	/>
))(({ theme }) => ({
	color: '#000',
	background: '#999999',
	textTransform: 'uppercase',
	// flexDirection: 'row-reverse',
	// padding: 0,
	'& .MuiAccordionSummary-content': {
		marginRight: '10px',
	},
	'& .MuiAccordionSummary-expandIconWrapper': {
		transition: 'transform 0.3s ease'
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

function Info() {
	const [expanded, setExpanded] = useState(false);

	const handleChange = () => () => {
		setExpanded(!expanded);
	};

	return (
		<div className="Info">
			<div className="Info__Box">
				<Accordion expanded={expanded} onChange={handleChange()}>
					<AccordionSummary>
						<Typography>Tickets für die verschobenen Konzerte behalten ihre Gültigkeit</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Stack spacing={2}>
							<Typography>Generell behalten alle bereits gekauften Tickets für den Ersatztermin ihre Gültigkeit. Sollte die Show in eine andere Location verlegt werden, behalten bereits gekaufte Tickets auch für den neuen Veranstaltungsort ihre Gültigkeit.</Typography>
							<Typography>Weitere Infos zur Verlegung findet ihr <a href="https://landstreicher-booking.de/faber-faq" target="_blank" rel="noreferrer" style={{color: '#fff'}}>hier</a>.</Typography>
						</Stack>
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
}

export default Info;