import React from 'react';

import Logo from '../assets/faber_logo_2022.svg'

function Header() {
	return (
		<div className="Header">
			<div className="Header__LogoContainer">
				<img className="Header__Logo" src={Logo} alt="Faber Logo" />
				<h2>Aus gegebenem Anlass findest du auf dieser Seite alle Informationen zu den aktuellen FABER Tourdaten und Verlegungen.</h2>
				{/* <h3>Link zur Faber Website: <a href="https://fabermusik.de/" target="_blank">fabermusik.de</a></h3> */}
			</div>
		</div>
	);
}

export default Header;