import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchApp } from './features/wordpress/wordpressSlice';

import Header from './components/Header';
import InfoLive2021 from './components/InfoLive2022';
import Events from './components/Events';
import Footer from './components/Footer';

import Imprint from './Imprint';
import Privacy from './Privacy';

import './App.scss';

function App() {
	/// START: Redux
	const dispatch = useDispatch();

	// This is the API data
	const data = useSelector((state) => state.wordpress);
	// Fetch the API data if we didn't already
	useEffect(() => {
		if (data.status === 'idle') {
			dispatch(fetchApp());
		}
	}, [data, dispatch])
	console.log("API data", data);
	/// END: Redux

	// #E63F10 ***

	// return (
	// 	<div className="App">
	// 		<span className="App__Loading">Infos kommen bald...</span>
	// 	</div>
	// )

	if (data.status === 'loading') {
		return (
			<div className="App">
				<span className="App__Loading">Loading...</span>
			</div>
		)
	}

	if (window.location.pathname === "/imprint") {
		return (
			<div className="App">
				<Imprint />
				<Footer />
			</div>
		);
	}

	if (window.location.pathname === "/privacy") {
		return (
			<div className="App">
				<Privacy />
				<Footer />
			</div>
		);
	}

	const eventsAt = data.app.data?.countries["at-ch"]?.events || [];
	const eventsDe = data.app.data?.countries["de"]?.events || [];

	const events = eventsAt.concat(eventsDe).sort((a, b) => {
		const aa = a.meta.event_date.split('/').reverse().join();
        const bb = b.meta.event_date.split('/').reverse().join();

  		return aa < bb ? -1 : (aa > bb ? 1 : 0);
	});

	console.log(events);

	// .sort((a, b) => {
	// 	const aa = a.split('/').reverse().join();
    //     const bb = b.split('/').reverse().join();

  	// 	return aa < bb ? -1 : (aa > bb ? 1 : 0);
	// })

	return (
		<div className="App">
			<Header />
			<Events events={events} status={data.status} title="Live 2022" banner={
				<InfoLive2021 />
			}/>
			{/* <Events events={data.app.data?.countries["de"]?.events} status={data.status} title="Promotour 2022" banner={
				<Info2022 />
			}/> */}
			<Footer />
		</div>
	);
}

export default App;
